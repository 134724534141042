<template>
  <div class="p-4">
    <h3>Awards</h3>

    <div class="mb-4 border-0">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1" v-for="category in category.children" :key="category.id">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`category-${category.id}`" variant="default">
              {{ category.name }}
            </b-button>
          </b-card-header>
          <b-collapse :visible="isCategoryVisible(category)"
                      :id="`category-${category.id}`"
                      @hide="handleHidden"
                      @show="handleShown(category)"
                      accordion="awards-accordion" role="tabpanel"
                      v-slot="{visible}">
            <component v-if="category.enabled && visible" :is="category.component" :category="category"/>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'officials-awards',
  props: {
    category: Object,
  },
  components: {
    affiliates: () => import('@/views/components/officials/Affiliates.vue'),
    'affiliates-with-triennial': () => import('@/views/components/officials/AffiliatesWithTriennial.vue'),
    'affiliates-with-selectors': () => import('@/views/components/officials/AffiliatesWithSelectors.vue'),
  },
  async mounted() {
    await this.scrollTo(`category-${this.$route.query.category}`);
  },
  methods: {
    isCategoryVisible(category) {
      return this.$route.query.category === category.id;
    },
    handleHidden() {
      this.$router
        .push({ path: '/affiliates/officials', query: { category: this.category.id } })
        .catch(() => {
          // skip duplicated warning
        });
    },
    handleShown(category) {
      if (this.$route.query.category === category.id) {
        return;
      }

      this.$router
        .push({ path: '/affiliates/officials', query: { category: category.id } })
        .catch(() => {
          // skip duplicated warning
        });
    },
    scrollTo(id) {
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
  },
};
</script>
